import { Ajax, Apis, Envs, Storage } from '../../common';
// @ts-ignore
import { AjaxResponse } from '../../types';

enum ShareMethod {
	MESSAGE = 'onMenuShareAppMessage',
	TIMELINE = 'onMenuShareTimeline',
	QQ = 'onMenuShareQQ',
	WEIBO = 'onMenuShareWeibo'
}
type ShareData = {
	title: string;
	desc: string;
	link: string;
	imgUrl: string;
};
class Wechat {
	private static isEnabled() {
		return Envs.isWechat();
	}

	/**
	 * 隐藏右上角菜单
	 * @returns 
	 */
	static hideOptionMenu(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			//隐藏右上角菜单
			wx.hideOptionMenu();
		});
	}

	/**
	 * 开启右上角菜单
	 * @returns 
	 */
	static showOptionMenu(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			//开启右上角菜单
			wx.showOptionMenu();
		});
	}

	static closeWindow(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			//关闭微信窗口
			wx.closeWindow();
		});
	}

	static forbiddenFontSizeChange(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			// 设置网页字体为默认大小
			wx.invoke('setFontSizeCallback', {
				'fontSize': 0
			});
			// 重写设置网页字体大小的事件
			wx.on('menu:setfont', function () {
				wx.invoke('setFontSizeCallback', {
					'fontSize': 0
				});
			});
		});
	}

	static shieldingMenuItems(): void {
		if (!this.isEnabled()) {
			return;
		}
		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			Ajax.post(Apis.WECHAT_SIGNATURE, {
				// FIXME 微信iphone url有注册问题, 必须使用进入的第一个url注册
				url: Envs.isIphone() && Envs.isWechat() ? Storage.Share.getFirstUrl() : window.location.href
			}).then((response: AjaxResponse) => {
				const data = (response.body || {}).body;
				if (data) {
					wx.config({
						debug: false,
						appId: data.appid,
						timestamp: data.timestamp,
						nonceStr: data.nonceStr,
						signature: data.signature,
						jsApiList: [
							'hideMenuItems'
						]
					});

					wx.ready(function () {
						setTimeout(() => {      //把按钮的隐藏与显示放定时器里，可以解决苹果IOS系统按钮隐藏与显示不生效问题
							wx.hideAllNonBaseMenuItem();
							wx.hideMenuItems({
								menuList: [
									'menuItem:share:appMessage',    //发送给朋友
									'menuItem:share:timeline',    //分享到朋友圈
									'menuItem:share:qq', //QQ
									'menuItem:share:weiboApp', //微博
									'menuItem:share:QZone',
									'menuItem:share:facebook',
									'menuItem:favorite',
									'menuItem:originPage',
									'menuItem:openWithQQBrowser',
									'menuItem:openWithSafari',
									'menuItem:share:email',
									'menuItem:share:brand',
									'menuItem:copyUrl'    //复制链接
								]
							});
						}, 400)
					});

					wx.error((res: any) => {
						// alert(JSON.stringify(res));
						console.error(res);
					});
				} else {
					console.error('获取微信配置信息失败');
				}
			});
		});

	}

	static initialize(shareData: ShareData): void {
		console.info('Share to: ', shareData.link);
		if (!this.isEnabled()) {
			return;
		}
		// webasset URL处理，图片URL中未包含https，则补充协议和域名
		if (shareData.imgUrl && shareData.imgUrl.indexOf('https:') < 0) {
			shareData.imgUrl = 'https://' + window.location.host + shareData.imgUrl;
		}

		import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
			const wx = data.default;
			//判断是否是微信小程序环境
			if (Envs.isWechatMiniProgram()) {
				//向小程序发送信息
				wx.miniProgram.postMessage({
					data: {
						action: 'share',
						title: shareData.title,
						desc: shareData.desc,
						shareUrl: shareData.link
					}
				});
			}

			const share = Wechat.createShare(wx);
			Ajax.post(Apis.WECHAT_SIGNATURE, {
				// FIXME 微信iphone url有注册问题, 必须使用进入的第一个url注册
				url: Envs.isIphone() && Envs.isWechat() ? Storage.Share.getFirstUrl() : window.location.href
			}).then((response: AjaxResponse) => {
				const data = (response.body || {}).body;
				if (data) {
					wx.config({
						debug: false,
						appId: data.appid,
						timestamp: data.timestamp,
						nonceStr: data.nonceStr,
						signature: data.signature,
						jsApiList: [
							//'checkJsApi',
							'onMenuShareTimeline',
							'onMenuShareAppMessage'
							// 'onMenuShareQQ',
							// 'onMenuShareWeibo'
							//'hideMenuItems'
						]
					});

					wx.ready(function () {
						//1 监听“分享给好友”按钮点击、自定义分享内容及分享结果接口
						share(shareData, ShareMethod.MESSAGE, 1000);
						// 2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
						share(shareData, ShareMethod.TIMELINE, 1000);
						// share(shareData, 'onMenuShareQQ', 4000);
						// share(shareData, 'onMenuShareWeibo', 5000);
					});

					wx.error((res: any) => {
						console.error(res);
					});
				} else {
					console.error('获取微信配置信息失败');
				}
			});
		});
	}
	/**
	 * 创建share监听器
	 */
	private static createShare(wx: any): (data: any, method: ShareMethod, delay: number) => void {
		return (data: any, method: ShareMethod, delay: number = 0) => {
			const link = document.createElement('a');
			switch (method) {
				case ShareMethod.MESSAGE:
					link.onclick = () => {
						wx.onMenuShareAppMessage(data);
					};
					break;
				case ShareMethod.TIMELINE:
					link.onclick = () => {
						wx.onMenuShareTimeline(data);
					};
					break;
				case ShareMethod.QQ:
					link.onclick = () => {
						wx.onMenuShareQQ(data);
					};
					break;
				case ShareMethod.WEIBO:
					link.onclick = () => {
						wx.onMenuShareWeibo(data);
					};
					break;
				default:
					return;
			}
			setTimeout(() => {
				link.click();
				link.remove();
			}, delay);
		};
	}
}

export default Wechat;
